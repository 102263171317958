<template>
	<main
		class="container-white"
		v-if="isAllow"
	>
		<section
			class="container-base"
			:class="$s.wrapper"
		>
			<div :class="$s.breadcrumb">
				<router-link to="/">VB</router-link>
				/
				<router-link
					to="/personal"
					@click.native="moveToFirstPage"
				>Личный кабинет
				</router-link>
				/
				{{ $route.meta.name }}
			</div>
			<div :class="$s.title">Личный кабинет</div>
			<Points :class="$s.points"/>
			<PromoLeft :class="$s.promo"/>
			<Menu :class="$s.menu"/>
			<router-view :class="$s.main"/>
		</section>
	</main>
</template>

<script>
import Menu from '@/components/block/Personal/Menu.vue'
import Points from '@/components/block/Personal/Points.vue'
import PromoLeft from '@/components/block/Personal/PromoLeft.vue'
import {mapState, mapMutations} from 'vuex'

export default {
	components: {Menu, Points, PromoLeft},
	data()
	{
		return {
			baseUrl: process.env.BASE_URL,
		}
	},
	methods: {
		...mapMutations('personal', ['setOrdersCurrentPage']),
		moveToFirstPage()
		{
			window.scrollTo(0, 0);
			this.setOrdersCurrentPage(1);
		}
	},
	computed: {
		...mapState(['user']),
		isAllow()
		{
			if (this.user) return true;

			window.location.href = this.baseUrl + 'auth/';
		}
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.wrapper
{
	display: grid;
	grid:
            "breadcrumb breadcrumb"
            "title title"
            "points main"
            "promo main"
            "menu main" 1fr
            "login login"
            / 352px 1fr;
	gap: 8px 32px;
	padding-bottom: 20px;
	align-content: start;

	@media (max-width: 875px)
	{
		grid:
                "breadcrumb"
                "title "
                "points "
                "promo "
                "menu"
                "main";
	}

	.breadcrumb
	{
		grid-area: breadcrumb;
		font-family: $mainFont;
		font-size: 12px;
		line-height: 16px;
		color: #979797;
	}

	.title
	{
		grid-area: title;
		font-size: 32px;
		line-height: 40px;
		color: #3D4248;
		font-family: $mainFontBold;
		@media (max-width: 875px)
		{
			font-size: 22px;
			line-height: 100%;
		}
	}

	.points
	{
		grid-area: points;
		max-width: calc(100vw - 20px);
	}

	.promo
	{
		grid-area: promo;
		border-radius: 10px;
		background: #FFFFFF;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
		padding: 1em 1.5em;
		max-width: calc(100vw - 20px);
	}

	.menu
	{
		grid-area: menu;
		font-size: 16px;
		align-self: start;
	}

	.main
	{
		grid-area: main;
		align-self: start;
		min-width: 0;
	}
}

.login
{
	:global .logo-block
	{
		display: none;
	}
}
</style>
<template>
	<div>
		<div :class="$s.inv">
			<div :class="$s.text">
				Приглашайте друзей по своему промокоду
			</div>
			<div
				:class="$s.code"
				@click="copy(promoCode)"
			>
				{{ promoCode }}
			</div>
		</div>
		<div :class="$s.link">
			<div>Ваша промо-ссылка:</div>
			<a
				:href="promoLink"
				@click.prevent="copy(promoLink)"
			>{{ promoLink }}</a>
		</div>
		<Promo/>
	</div>
</template>

<script>
import Promo from '@/components/block/Personal/Promo.vue'
import {mapState, mapActions} from 'vuex'

export default {
	components: {Promo},
	computed: {
		...mapState('personal', ['promoCode', 'promoLink']),

	},
	methods: {
		...mapActions('personal', ['loadLoyaltyInfo']),
		copy(val)
		{
			navigator.clipboard.writeText(val)
		}
	},
	mounted()
	{
		this.loadLoyaltyInfo();
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.inv
{
	display: flex;
	flex-wrap: wrap;

	.text
	{
		flex: 1 0 60%;
		font-family: $mainFontMedium;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 23px;
		width: 100%;
	}

	.code
	{
		/*flex: 1 0 ;*/
		margin-top: 15px;
		width: 100%;
		padding: 0 10px;
		font-family: $mainFontBold;
		background: $green;
		border: 1px solid white;
		box-sizing: border-box;
		border-radius: 6px;
		color: white;
		font-weight: bold;
		font-size: 19px;
		line-height: 24px;
		height: 48px;
		text-align: center;
		align-content: center;
		display: grid;
		@media (max-width: 420px)
		{
			font-size: 16px;
			line-height: 16px;
		}
	}
}

.link
{
	margin: 15px 0;
	padding: 10px 24px;
	background: $greyBackground;
	border: 1px solid $greyBorder;
	box-sizing: border-box;
	border-radius: 4px;

	a
	{
		color: $green;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
	}

	div
	{
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: $greyLightText;
		width: 100%;
	}
}
</style>
<template>
	<div :class="$s.wrapper">
		<router-link
			v-for="el in list"
			:key="el.to"
			:to="{name:el.to !== 'delete_account' ? el.to : ''}"
			:class="{[$s.row]:1, [$s.current]: $route.name.startsWith(el.to)  }"
		>
			<div
				:class="$s.img"
				@click="el.to === 'delete_account' && toggleDeleteAccountPopup()"
			>
				<div
					:class="$s.mask"
					:style="{'mask-image': `url(${el.img})`}"
				/>
			</div>
			<span @click="el.to === 'delete_account' && toggleDeleteAccountPopup()">{{ el.name }}</span>
			<div
				v-if="el.to !== 'delete_account'"
				:class="$s.arrow"
			/>
		</router-link>
		<ConfirmationPopup
			:visible="deleteAccountPopup"
			title="Вы уверены?"
			subtitle="Удаленный аккаунт не подлежит восстановлению."
			@closePopup="toggleDeleteAccountPopup"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="fetchDeleteAccount(); toggleDeleteAccountPopup();"
				>Удалить
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
	data()
	{
		return {
			list: [
				{name: 'Мои заказы', to: "personal_orders", img: require('@/assets/img/Personal/Menu/cart.svg')},
				{
					name: 'Программа лояльности',
					to: "personal_loyalty",
					img: require('@/assets/img/Personal/Menu/loyalty.svg')
				},
				{name: 'Моя информация', to: "personal_info", img: require('@/assets/img/Personal/Menu/info.svg')},
				{
					name: 'Способы оплаты',
					to: "personal_pay",
					img: require('@/assets/img/Personal/Menu/pay.svg')
				},
				{name: 'Удалить аккаунт', to: "delete_account", img: require('@/assets/img/trash.svg')},
				// {name:'Адресная книга', to:"personal_address", img:require('@/assets/img/Personal/Menu/address.svg')  },
			],
			deleteAccountPopup: false,
		}
	},
	components: {
		ConfirmationPopup: () => import("@/components/popup/ConfirmationPopup"),
	},
	methods:
		{
			...mapActions({
				fetchDeleteAccount: 'fetchDeleteAccount',
				fetchCards: 'personal/fetchCards',
			}),
			toggleDeleteAccountPopup()
			{
				this.deleteAccountPopup = !this.deleteAccountPopup;
			}
		},
	async mounted()
	{
		await this.fetchCards();
	}
}
</script>

<style
	lang="scss"
	module="$s"
>
.wrapper
{
	display: grid;
	border-radius: 10px;
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	color: #3D4248;
	font-weight: 600;
	font-family: $mainFont;
	@media (max-width: 875px)
	{
		grid-auto-flow: column;
		border-radius: 0;
		border: none;
	}

	.row
	{
		font-size: 1em;
		line-height: 1.5em;
		height: 4.5em;
		border-bottom: 1px solid #F0F0F0;
		padding: 0 1.5em;
		display: grid;
		grid-template-columns: max-content 1fr 15px;
		align-content: center;
		gap: 1em;
		@media (max-width: 875px)
		{
			padding: 10px 0;
			grid-template-columns: 1fr;
			height: 100%;
		}

		&.current
		{
			background: #F0F8F0;

			.img
			{
				background: #66BE6D;

				.mask
				{
					background: white;
				}
			}

			span
			{
				color: #40AE49;
			}

			.arrow
			{
				background: #979797;
			}
		}


		.img
		{
			background: #F8F8F8;
			height: 3em;
			width: 3em;
			border-radius: 3em;
			place-self: center;

			@media (max-width: 875px)
			{
				height: 2.5em;
				width: 2.5em;
			}

			.mask
			{
				mask-repeat: no-repeat;
				mask-position: center;
				background: #40AE49;
				height: 100%;
				@media (max-width: 875px)
				{
					mask-size: 50%;
				}
			}
		}

		span
		{
			align-self: center;
			@media (max-width: 875px)
			{
				display: none;
			}
		}

		.arrow
		{
			mask: url('~@/assets/img/Personal/Menu/arrow.svg') no-repeat center;
			background: #40AE49;
			@media (max-width: 875px)
			{
				display: none;
			}
		}

	}
}
</style>

